var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex flex-row flex-1 space-x-4 overflow-hidden"},[_c('ExecutionsFilters',{staticClass:"flex flex-row px-1 w-72",attrs:{"searchPlaceholder":"Search by analytics or data check-in pipeline ID","maxlength":36},on:{"change":_vm.handleFiltersChange}}),(_vm.executionQuery)?_c('div',{staticClass:"flex flex-col flex-1"},[(_vm.rows.length && _vm.executionQuery.search)?_c('TailwindTable',{attrs:{"columns":_vm.columns,"rows":_vm.rows,"loading":_vm.loading,"pagination":_vm.pagination,"sortBy":_vm.query.sortBy},on:{"change-page":_vm.changePage,"sort":_vm.handleChangeSort},scopedSlots:_vm._u([{key:"type",fn:function(ref){
var value = ref.value;
return [(value)?_c('span',{staticClass:"inline-flex items-center px-2 py-1 text-xs font-medium uppercase rounded",class:{
                        'bg-green-100 text-green-800': value.toLowerCase() === 'normal',
                        'bg-red-100 text-red-800': value.toLowerCase() === 'longrunning',
                        'bg-yellow-100 text-yellow-800': value.toLowerCase() === 'test',
                        'bg-gray-100 text-gray-800': value.toLowerCase() === 'dry',
                    }},[_vm._v(" "+_vm._s(value)+" ")]):_vm._e()]}},{key:"status",fn:function(ref){
                    var value = ref.value;
return [(value)?_c('span',{staticClass:"inline-flex items-center px-2 py-1 text-xs font-medium uppercase rounded",class:{
                        'bg-green-200 text-green-800': value.toLowerCase() === 'completed',
                        'bg-red-200 text-red-800': value.toLowerCase() === 'failed',
                        'bg-blue-200 text-blue-800': value.toLowerCase() === 'running',
                        'bg-gray-200 text-gray-800': value.toLowerCase() === 'queued',
                        'bg-orange-200 text-orange-700': value.toLowerCase() === 'cancelled',
                    }},[_vm._v(" "+_vm._s(value)+" ")]):_vm._e()]}},{key:"actions",fn:function(ref){
                    var row = ref.row;
return [(row.workflowId)?_c('DocumentTextIcon',{directives:[{name:"tooltip",rawName:"v-tooltip",value:("Copy execution configuration"),expression:"`Copy execution configuration`"}],staticClass:"w-5 outline-none cursor-pointer text-neutral-500 hover:text-neutral-700",on:{"click":function($event){return _vm.fetchExecutionConfiguration(row.id)}}}):_vm._e()]}}],null,false,2425155888)}):_c('div',{staticClass:"flex flex-col flex-1"},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.loading),expression:"!loading"}],staticClass:"flex flex-col flex-1"},[(!_vm.executionQuery.query)?_c('AlertBanner',{staticClass:"mt-0 text-sm",attrs:{"description":"Please search by analytics or data check-in pipeline ID to view executions.","title":"Info","icon":"","type":"info"}}):(!_vm.rows.length && !_vm.errorMessage)?_c('AlertBanner',{staticClass:"mt-0 text-sm",attrs:{"description":"No executions found for the given search criteria.","title":"Warning","icon":"","type":"warn"}}):(_vm.errorMessage)?_c('AlertBanner',{staticClass:"mt-0 text-sm",attrs:{"description":_vm.errorMessage,"title":"Error","icon":"","type":"error"}}):_vm._e()],1),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.loading),expression:"loading"}],staticClass:"flex flex-col flex-1"},[_c('SelfBuildingSquareSpinner',{staticClass:"mx-auto",attrs:{"animation-duration":5000,"size":36,"color":"#b7791f"}})],1)]),_c('input',{ref:"executionConfigRef",attrs:{"type":"hidden"}})],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }